.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 0.5rem;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.study-browser .scrollable-study-thumbnails .study-thumbnail-group {
  border-bottom: 1px solid var(--ui-border-color-dark);
  background: var(--ui-gray-dark);
}
.study-browser .scrollable-study-thumbnails .study-info-thumbnails {
  background: var(--primary-background-color);
  padding-top: 10px;
}
.study-browser .scrollable-study-thumbnails .study-info {
  color: var(--default-color);
  display: flex;
  padding: 10px 20px;
  align-items: center;
  cursor: pointer;
}
.study-browser .scrollable-study-thumbnails .study-info:hover {
  color: var(--hover-color);
}
.study-browser .scrollable-study-thumbnails .study-info-title {
  flex: 1;
}
.study-browser .scrollable-study-thumbnails .study-info-title-desc {
  font-size: 12px;
}
.study-browser .scrollable-study-thumbnails .study-info-modality {
  display: inline-block;
  padding: 0px 2px;
  margin-left: 10px;
  border: 1px solid rgba(132,162,189,0.5);
  border-radius: 2px;
}
.study-browser .scrollable-study-thumbnails .chevron-down {
  transition: transform 200ms;
}
.study-browser .scrollable-study-thumbnails .chevron-down-right {
  transform: rotate(-90deg);
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
